@import url("https://fonts.googleapis.com/css2?family=Days+One&display=swap");

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  /* Light grey */
  border-top: 10px solid #383636;
  /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 350px;
}

.days-one {
  font-family: "Days One", sans-serif;
  font-weight: 200 !important;
}

.navbar-brand img {
  height: 80px !important;
}

.navbar-collapse {
  background-color: default;
}

@media (max-width: 768px) {
  .navbar-brand img {
    height: 40px !important;
  }
}

.bg-default {
  background-color: none !important;
  background-image: url("../img/bg-main.jpg") repeat !important;
}

.bg-gradient-info {
  background: linear-gradient(87deg, #1a61bd 0, #003171 100%) !important;
}

.jumbo-text {
  font-size: 2.5rem;
  font-weight: 700;
  color: #fff;
}

.null-border-radius {
  border-radius: 10px !important;
}

.border-radius-45 {
  border-radius: 20px !important;
}

.border-radius-10 {
  border-radius: 10px !important;
}

.border-1px {
  border: 1px solid black !important;
}

.active-button,
.active-button:hover,
.active-button:active {
  box-shadow: 7px 7px 7px #999 !important;
  border: 1px solid #999;
}

.semi-active-button,
.semi-active-button:hover,
.semi-active-button:active {
  box-shadow: 3px 3px 3px #ccc !important;
  border: 1px solid #ccc;
}

#report-preview {
  padding-top: 20px;
  height: 100%;
  min-height: 1000px;
  width: 100%;
  overflow-y: scroll;
}

.list-widget-table,
.list-widget-table th,
.list-widget-table td {
  word-wrap: break-word;
  vertical-align: middle;
  padding-top: 0;
  padding-bottom: 0;
}

.width-100 {
  width: 100% !important;
}

.width-100-on-lg {
  width: 100%;
}

.width-50 {
  width: 50%;
}

.width-80-on-lg {
  width: 80% !important;
}

.width-90-on-lg {
  width: 90% !important;
}

.width-40-on-lg {
  width: 40%;
}

.larger-text {
  font-size: 1.2rem;
}

.min-height-200 {
  min-height: 200px !important;
}

.min-height-100 {
  min-height: 100px !important;
}

.dropdown-toggle btn-lg {
  width: 100%;
}

.employee-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 450px;
}

.MuiFormLabel-root {
  z-index: 0 !important;
}

.MuiTableBody-root {
  overflow: scroll;
}

.MuiTableCell-root {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.MuiDrawer-paperAnchorLeft {
  width: 50% !important;
}

.dropdown-item {
  z-index: inherit;
  overflow-x: visible;
  z-index: 2;
}

#sidenav-main {
  z-index: 5;
  overflow: visible;
}

.height-100px {
  height: 100px;
}

.height-80 {
  height: 80%;
}

.full-height {
  height: 80vh;
}

.bg-terminated {
  background-color: #ffccbc;
}

.bg-locked {
  background-color: #9cbaf0;
}

.font-heavy {
  font-weight: 700 !important;
}

.float-right-center-on-small {
  float: right;
  text-align: right;
}

.MuiSelect-select {
  background: white !important;
}

.MuiInputBase-input {
  padding: 0.625rem 0.75rem !important;
}

.bg-peach {
  background-color: #ffdab9 !important;
}

.Mui-disabled {
  background-color: #e9ecef !important;
}

.modal-dialog {
  min-width: 40%;
  max-width: fit-content;
  margin: 1.75rem auto;
  overflow-y: auto;
}

.min-width-100px {
  min-width: 100px;
}

.emp-nav-buttons {
  padding-left: 0;
  padding-right: 0;
}

.MuiMenuItem-root {
  min-height: 35px !important;
  border-bottom: 1px solid #e9ecef !important;
}

.min-height-220 {
  min-height: 220px;
}

.min-height-120 {
  min-height: 120px;
}

.min-height-100 {
  min-height: 100px;
}

.min-height-80 {
  min-height: 80px;
}

.navbar-vertical .navbar-nav .nav-link > i {
  font-size: 1.1rem;
}

.width-40px {
  width: 40px;
}

.MuiTooltip-tooltip {
  font-size: 0.9rem !important;
}

.invalid-feedback {
  color: red;
}

.auto-calc-checkbox {
  transform: "scale(0.8)" !important;
}

.text-peach {
  color: #d96748 !important;
}

.spacer-column {
  display: none;
}

@media (min-width: 1820px) {
  .spacer-column {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .w-sm-100 {
    width: 100% !important;
  }

  .larger-text {
    font-size: 14px;
  }

  .emp-nav-buttons {
    padding-left: 10px;
    padding-right: 10px;
  }

  .m-20-on-sm {
    margin: 20px !important;
  }

  .container-fluid {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .card .table td,
  .card .table th {
    padding: 0.5rem !important;
  }

  .width-80-on-lg {
    width: 100% !important;
  }

  .width-100-on-lg {
    width: auto;
  }

  .width-90-on-lg {
    width: 100% !important;
  }

  .width-40-on-lg {
    width: 100%;
  }

  .file-uploader-button {
    width: 100%;
    float: none;
  }

  .float-right-center-on-small {
    text-align: center;
    float: none;
    flex-direction: row;
  }

  body {
    min-width: 100vw !important;
  }

  .MuiDrawer-paperAnchorLeft {
    width: 90% !important;
  }
}

.pointer-cursor {
  cursor: pointer;
}

.hover-animate {
  transition: all 0.3s ease;
  /* This will make the hover effect animate smoothly */
}

.hover-animate:hover {
  transform: scale(1.1);
  /* This enlarges the button slightly */
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  /* This adds a shadow */
}

.overflow-card {
  overflow-x: auto;
  max-width: 100%;
}

.overflow-card::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

.overflow-card::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 3px;
}

.overflow-card::-webkit-scrollbar-track {
  background-color: lightgrey;
}

.form-control {
  color: #333333;
}

.text-black {
  color: #333333;
}

.text-dark-blue {
  color: #172b4d !important;
}

.text-decrease {
  color: #ffa200 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.btn-icon-clipboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 250px;
  min-height: 120px;
}

.min-height-auto {
  min-height: auto;
}

.align-items-start {
  align-items: start;
}

.align-items-center {
  align-items: center;
}

.input-group-alternative {
  display: flex;
  align-items: center;
}

.flex-fill {
  display: flex;
  flex-direction: column;
}

.flex-fill > div {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-center-flex {
  display: flex;
  justify-content: start;
  /* Horizontal */
  align-items: center;
  /* Vertical */
}

h1 {
  font-size: 2rem !important;
  font-weight: 500;
  line-height: 1.2;
}

.mui-style-input {
  display: inline-block;
  width: 100%;
  padding: 6px 12px;
  font-size: 1.2rem;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.87);
  background-color: #fff;
  background-image: none;
  border: 1px solid #ced4da;
  border-radius: 12px;
  text-align: center;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.mui-style-input:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-icon-button {
  flex-direction: column !important;
  border-radius: 12px !important;
  border: 1px solid #ccc !important;
  padding: 10px !important;
}

.table-container {
  width: 100%;
  overflow-x: auto;
}

.flex-stretch {
  display: flex;
  align-items: stretch;
  width: 100%;
}

.mui-style-input.flex-stretch input {
  width: 100%;
}

.widget-disabled {
  background-color: rgb(230, 230, 230) !important;
}

.width-20 {
  width: 20%;
}

.btn:hover {
  transform: scale(1.015);
}

.style-links a {
  text-decoration: underline;
  color: #333333;
}

.underline-link {
  text-decoration: underline;
}

.bg-primary-light {
  background-color: #f0f5ff !important;
}

.bg-success-light {
  background-color: #f0fff4 !important;
}

.bg-danger-light {
  background-color: #fff0f0 !important;
}

.bg-warning-light {
  background-color: #fff8e1 !important;
}

.list-widget-table .Mui-disabled {
  background-color: transparent !important;
}

.bg-lightblue {
  background-color: #c2eaff !important;
}

.min-height-600 {
  min-height: 600px;
}

@media (max-width: 767.98px) {
  .h1-responsive {
    font-size: 1.2rem !important; /* Adjust this size as needed */
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .h1-responsive {
    font-size: 1.5rem !important; /* Adjust this size as needed */
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .h1-responsive {
    font-size: 1.6rem !important; /* Adjust this size as needed */
  }
}

@media (min-width: 1200px) {
  .h1-responsive {
    font-size: 2rem !important; /* Adjust this size as needed */
  }
}

.accordion-menu-toggle {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
}

.accordion-menu-carat {
  margin-left: 5px;
}

@media (max-width: 968px) {
  .full-width-on-sm {
    flex-basis: 50% !important;
  }
}

/* flex basis should be 100% on very small screens */
@media (max-width: 576px) {
  .full-width-on-sm {
    flex-basis: 100% !important;
  }
}

.w-90 {
  width: 90% !important;
}

.leave-calculator-button-holiday {
  color: #52bb52;
  padding-bottom: 8px;
}

.leave-calculator-button-long-service {
  color: #ffd700;
  padding-bottom: 8px;
}

.leave-calculator-button-sick {
  padding-bottom: 8px;
}

@media (min-width: 768px) {
  .dashboard-combobox-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }
}

.lottie-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}

.max-width-50 {
  max-width: 50%;
}

.action-icon-size {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px; /* Adjust as needed */
  height: 24px;
}

.text-purple {
  color: #800080 !important;
}

.text-dark-pink {
  color: #ff1493 !important;
}

.text-error-red {
  color: #ff3333 !important;
}
